import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { baseUrl } from 'helpers/requests';

const AllSidesGraphs = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [categories, setCategories] = useState('Politics');
  const [includeCategories, setIncludeCategories] = useState(true);
  const [graphData, setGraphData] = useState(null);

  // Fetch data on initial load
  useEffect(() => {
    fetchGraphData();
  }, []); // Empty dependency array ensures this runs only once on mount

  const fetchGraphData = async () => {
    try {
      const queryParams = new URLSearchParams({
        startDate,
        endDate,
        categories,
        includeCategories
      });
      const response = await fetch(
        `${baseUrl}/api/articles-with-graph?${queryParams.toString()}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('access')).token
            }`
          }
        }
      );
      const { data } = await response.json();
      setGraphData(data);
    } catch (error) {
      console.error('Error fetching graph data:', error);
    }
  };

  const handleSubmit = e => {
    e.preventDefault(); // Prevent form submission from reloading the page
    fetchGraphData(); // Fetch data when the submit button is pressed
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="categories">
                      <Form.Label>Categories</Form.Label>
                      <Form.Control
                        type="text"
                        value={categories}
                        onChange={e => setCategories(e.target.value)}
                        placeholder="Enter categories (comma-separated)"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="includeCategories">
                      <Form.Label>Include Categories</Form.Label>
                      <Form.Check
                        type="switch"
                        checked={includeCategories}
                        onChange={e => setIncludeCategories(e.target.checked)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Button variant="primary" type="submit">
                      Fetch Data
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {graphData && (
        <>
          <Row className="mb-4">
            <Col className="md-6">
              <Card>
                <Card.Header>Individual Value-Based Bar Chart</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.individualBarChart}
                    style={{ height: '400px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col className="md-6">
              <Card>
                <Card.Header>Grouped Range-Based Bar Chart</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.groupedBarChart}
                    style={{ height: '400px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Header>Bias Comparison Across LLMs</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.biasComparisonBarChart}
                    style={{ height: '400px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Header>Bias Distribution Across Stories</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.heatmap}
                    style={{ height: '1000px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Header>Average Bias Trends Across LLMs</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.lineChart}
                    style={{ height: '400px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Header>Bias Score Distribution Across LLMs</Card.Header>
                <Card.Body>
                  <ReactECharts
                    option={graphData.boxPlot}
                    style={{ height: '400px', width: '100%' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AllSidesGraphs;
